<template>
  <div class="app-404">404 Page Not Found</div>
</template>

<script>

export default {
  name: 'ERROR404',
  async mounted() {
    setTimeout(()=>{
      this.$router.push({path: '/'})
    },3000)
  },
}
</script>

<style lang="scss">
.app-404 {
  margin-top: 200px;
  font-size: 42px;
  font-weight: bold;
  color: #333333;
}
</style>
